<!-- 查看准考证 -->
<template>
    <div class="seeGrant">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">准考证</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
            
        </div>
        <div class="title">
            中国美术学院社会美术水平考级中心准考证
        </div>
        <!-- 表单 -->
        <div class="" style="display: flex;flex: 1;flex-direction: column;">
            <div class="" style="width: 100%;display: flex;justify-content: space-around;">
                <div class="" style="flex: 1;">
                    <el-form ref="form" :model="form"  class="form" inline label-width="120px">
                        <el-form-item label="姓名" >
                            <el-input v-model="form.name" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号码" >
                            <el-input v-model="form.IDcard" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="性别" >
                            <el-input :value="form.sex" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="国籍" >
                            <el-input v-model="form.nationality" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="民族" >
                            <el-input v-model="form.nation" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="准考证号码" >
                            <el-input v-model="form.admission" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="专业" >
                            <el-input v-model="form.major" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="级别" >
                            <el-input v-model="form.level" class="level" placeholder="" disabled ></el-input>
                            <!-- 印章 -->
                            <div class="seal">
                                <img src="../../assets/zhang.png" >
                            </div>
                        </el-form-item>
                        <el-form-item label="考场地址" >
                            <el-input v-model="form.address" placeholder="" disabled class="address"></el-input>
                           
                        </el-form-item>
                        <el-form-item label="机构名称" >
                            <el-input v-model="form.mechanism" placeholder="" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="考试时间" >
                            <el-input v-model="form.testDate" placeholder="" disabled></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="" style="margin:10px 70px 0px 50px;">
                    <span style="color: #666;margin-bottom: 7px;display: inline-block;">照片</span>
                    <div class="Photo">
                        <img :src="picImg" >
                    </div>
                </div>    
            </div>
            <el-divider></el-divider>
            <!-- 底部 -->
            <div class="" style="padding-bottom: 20px;">
                <div class="Notice">
                    考生须知
                </div>
                <div class="" style="margin-left: 30px;margin-top: 10px;">
                    1.考卷由中国美术学院考级专家委员会统一命题,在考级中心统一评卷,之后统一发证。</br>
                    2.考生拿到准考证后要仔细核对,如有疑问请与学院考级办联系。</br>
                    3.考生须持准考证并按照准考证上规定的时间、地点和考场参加考试,遵守考试纪律,家长一律不得进入考场,无准考证或准考证与本人身份不符者不得进入考场。</br>
                    4.素描、色彩考生应自带画板或画夹,考生根据考纲带好考试用工具,除考试用具外,考生将其余所带的物品,交监考老师集中保管(贵重物品除外),考试用纸由考级办统一配发。</br>
                    5.考生需再次核对准考证号码、姓名、报考科目和级别等信息
                </div>
            </div>
            <!-- 二维码 -->
            <div class="" style="text-align: center;padding-top: 30px;">
                <div class="ervm">
                    <img :src="QRcode" >
                </div>
                <span style="font-size: 14px;color: #333;">扫一扫,查看电子版准考证</span>
            </div>
        </div> 
        <el-divider></el-divider>
        <!-- 底部 -->
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>   
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    name:"",//姓名
                    IDcard:"",//身份证号码
                    sex:"",//性别
                    nationality:"",//国籍
                    nation:"",//民族
                    admission:"",//准考证号码
                    major:"",//专业
                    level:"",//级别
                    address:"",//考场地址
                    mechanism:"",//机构名称
                    testDate:""//考试时间                   
                },
                picImg:require("../../assets/logo.png"),//相片
                QRcode:"",//二维码
            }
        },
         created() {
            // console.log(this.info)
            // 获取个人信息
            this.$request({url:'/api/studentexam/info',method:'POST',data:{
                uid: localStorage.getItem('token')
            }}).then(res=>{
                console.log(res)
                let data = res.data
                this.form.name = data.username//姓名  
                this.form.IDcard=data.idcard//身份证
                
                this.form.nationality=data.nation2//国籍
                this.form.nation=data.nation//民族
                this.picImg=data.head_image//相片
                
            })
            
            
            this.$request({url:'/api/studentexam/admission',method:'POST',data:{
                uid: localStorage.getItem('token'),
                signup_id:this.$route.query.id
            }}).then(res=>{
                // console.log(res)
                this.form.admission=res.data.certificate_no//准考证号码
                this.form.major=res.data.course//专业
                this.form.level=res.data.exam_level//级别
                this.form.sex=res.data.gender//性别
                this.form.address=res.data.exam_address//考场地址
                this.form.mechanism=res.data.org_name//机构名称
                this.form.testDate=res.data.exam2_date//考试时间
                this.QRcode=res.data.qrcode
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .seeGrant{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
       
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 30px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 标题文字 */
    .title{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    /* 表单部分 */
    .el-form {
        margin-top: 30px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .form ::v-deep.el-input__inner {
        width: 300px !important;
        height: 37px !important;
    }
    
    .form ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #fff;
        color: #606266;
    }
    /* 照片 */
    .Photo{
        width: 173px;
        height: 211px;
        border: 1px solid #ccc;
    }
    .Photo img{
        width: 100%;
        height: 100%;
    }
    /* 印章 */
   .level{
        position: relative;
    }
    .seal{
       position: absolute;
       top: -20px;
       left: 160px;
       z-index: 222;
    }
    .seal img{
        width: 190px;
        height: 128px;
    }
    /* 文字内容 */
    .Notice{
        margin-left: 30px;
        font-weight: bold;
    }
    /* 二维码 */
    .ervm img{
        width: 100px;
        height: 100px;
        /* border: 1px solid #ccc; */
    }
    /* 底部 */
    .buttom {
        height: 20px;
        line-height: 20px;
        padding: 0px 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
    }
</style>
